import React from "react";
import { Spinner } from 'react-bootstrap';

export function PageLoading() {
    return (
        <div className="full-page-loader">
            <Spinner animation="border" role="status" />
        </div>
    )
}

export default PageLoading
