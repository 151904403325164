/** @format */

import React, { useEffect, useState } from "react";
import {
  Spinner,
  Breadcrumb,
  Image,
  Button,
  ToggleButton,
  Col,
  Row,
  Form,
  Tabs,
  Tab,
  Card,
  Modal,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "../../services/firebase";
import moment from "moment";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faDoorOpen,
  faSave,
  faEdit,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import bankPng from "../../assets/images/bank.png";

import "./Billing.scss";
import { Header } from "../../components/Header";
import getDollarAtTime from "../../constants/DollarRate";

function numberWithCommas(x) {
  return x.toLocaleString(); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function Billing(props) {
  const initFormValue = {
    name: "",
    bank: "",
    account: "",
    swift: "",
  };
  const [formValue, setFormValue] = useState(initFormValue);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [manualTxs, setManualTxs] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const [uniqueUsers, setUniqueUsers] = useState(false);
  const [users, setUsers] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [days, setDays] = useState([]);
  const [data, setData] = useState();
  const [net, setNet] = useState(0);
  const [total, setTotal] = useState(0);
  const [nPayments, setnPayments] = useState(0);
  const [acceptPayments, setAcceptPayments] = useState(0);
  const [stripePayments, setStripePayments] = useState(0);
  const [vodafonePayments, setVodafonePayments] = useState(0);
  const [manualPayments, setManualPayments] = useState(0);
  const [acceptPaymentsEGP, setAcceptPaymentsEGP] = useState(0);
  const [stripePaymentsEGP, setStripePaymentsEGP] = useState(0);
  const [vodafonePaymentsEGP, setVodafonePaymentsEGP] = useState(0);
  const [manualPaymentsEGP, setManualPaymentsEGP] = useState(0);
  const [acceptPaymentsCount, setAcceptPaymentsCount] = useState(0);
  const [stripePaymentsCount, setStripePaymentsCount] = useState(0);
  const [vodafonePaymentsCount, setVodafonePaymentsCount] = useState(0);
  const [manualPaymentsCount, setManualPaymentsCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [payments, setPayments] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [challengeById, setChallengeById] = useState({});
  const [challengeNutritionist, setChallengeNutritionist] = useState({});
  const [paymentsLoading, setPaymentsLoading] = useState(true);
  const [challengesLoading, setChallengesLoading] = useState(true);
  const [selectedChallenge, setSelectedChallenge] = useState("");
  const [challenge, setChallenge] = useState();
  const [ignore, setIgnore] = useState({});
  const [rounds, setRounds] = useState(false);
  const [format, setFormat] = useState("MMM DD"); //YYYY-MM-DD
  const [formatMonth, setFormatMonth] = useState("YYYY MMM"); //YYYY-MM-DD
  const [nutritionists, setNutritionists] = useState({
    options: {},
    series: [],
  });

  const [sources, setSources] = useState({
    options: {},
    series: [],
  });

  const [showAll, setShowAll] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [admin, setAdmin] = useState(
    JSON.parse(localStorage.getItem("adminDetails"))
  );
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("adminDetails")).role.includes("admin")
  );
  const [hasCollaborator, setHasCollaborator] = useState({});
  const [collaborators, setCollaborators] = useState({});
  const [collaboratorName, setCollaboratorName] = useState({});
  const [nutritionistBankAccount, setNutritionistBankAccount] = useState({});
  const [payoutDetails, setPayoutDetails] = useState({});
  const [showPayout, setShowPayout] = useState(false);
  const [sortOrder, setSortOrder] = useState();
  const [sortItem, setSortItem] = useState();

  useEffect(() => {
    getChallenges();
    getUserData();
    getNutritionists();
    getPayouts();
  }, []);

  useEffect(() => {
    setLoader(true);
    var unsubscribes = getCollections();

    return () => {
      console.log("Do some cleanup");
      unsubscribes.map((unsubscribe) => unsubscribe());
    };
  }, [selectedChallenge]);

  useEffect(() => {
    var _sources = {};
    transactions
      .filter((t) => t.src)
      .map((t) => {
        if (!_sources[t.src]) _sources[t.src] = 0;
        _sources[t.src]++;
      });

    setSources({
      options: {
        labels: Object.keys(_sources),
        tooltip: { y: { formatter: (val) => val } },
        legend: {
          labels: {
            colors: "#ddd",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return val.toFixed(0) + "%";
          },
          style: {
            fontSize: "11px",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
              labels: {
                name: {
                  fontSize: "9px",
                },

                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      series: Object.values(_sources),
    });

    return () => {
      console.log("Do some cleanup");
    };
  }, [transactions]);

  function getPayouts() {
    return (
      firestore("payouts")
        //.get().then((querySnapshot) => {
        .onSnapshot(
          function (querySnapshot) {
            var payoutDetails = {};
            let data = querySnapshot.docs.map((documentSnapshot, i) => {
              payoutDetails[
                documentSnapshot.data().nutritionistId +
                  documentSnapshot.data().date
              ] = documentSnapshot.data();
              return {
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              };
            });
            setPayoutDetails(payoutDetails);
          },
          (error) => {
            console.log("error", error);
          }
        )
    );
  }

  function getNutritionists() {
    return firestore("users")
      .where("role", "array-contains-any", ["nutritionist", "collaborator"])
      .get()
      .then(
        (querySnapshot) => {
          var hasCollaborator = {};
          var collaborators = {};
          var collaboratorName = {};
          var nutritionistBankAccount = {};
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            if (
              documentSnapshot.data().collaborators &&
              documentSnapshot.data().collaborators.length
            ) {
              hasCollaborator[documentSnapshot.id] = true;
              collaborators[documentSnapshot.id] =
                documentSnapshot.data().collaborators;
            }
            nutritionistBankAccount[documentSnapshot.id] =
              documentSnapshot.data().bankAccount;
            collaboratorName[documentSnapshot.id] =
              documentSnapshot.data().name;

            return { ...documentSnapshot.data(), key: documentSnapshot.id };
          });
          delete hasCollaborator["wAHcTEeg2Ksph5Jm0mQm"]; //welnes has no collaborators for billing
          collaborators["2KM2jWdQkXyO2DqfKteC"] = collaborators[
            "2KM2jWdQkXyO2DqfKteC"
          ].filter((d) => d !== "o68rTvszFrsDHxRoE3by"); //remove nada from waad as a collaborator to not be billed in proceeds
          setHasCollaborator(hasCollaborator);
          setCollaborators(collaborators);
          setCollaboratorName(collaboratorName);
          setNutritionistBankAccount(nutritionistBankAccount);
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  function getChallenges() {
    setChallengesLoading(true);

    return firestore("challenges")
      .get()
      .then(
        (querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });

          firestore("one_to_one")
            .get()
            .then(
              (querySnapshot) => {
                let data2 = querySnapshot.docs.map((documentSnapshot, i) => {
                  return {
                    oneToOne: true,
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                  };
                });

                data = data.concat(data2);

                //_.orderBy(groups, 'userCount', 'desc')
                //data = _.orderBy(data.filter(d => d.isPublished && !d.isFree), 'price', 'desc')

                if (!admin.role.includes("admin"))
                  data = data.filter((d) =>
                    [admin.id]
                      .concat(admin.collaborate || [])
                      .includes(d.nutritionistId)
                  );

                //var include = []
                //data.map(d => {if(d.previousRounds) include = include.concat(d.previousRounds); else include = include.concat([d.key]); return d})

                //data = _.orderBy(data.filter(d => (include.includes(d.key))), item => moment.utc(item.date).format(), 'desc')

                data = _.orderBy(
                  data,
                  (item) => moment.utc(item.date).format(),
                  "desc"
                );

                var challengeById = {},
                  challengeNutritionist = {};
                data.map((d) => {
                  challengeById[d.key] = d;
                  challengeNutritionist[d.key] = d.nutritionistName;
                });
                setChallengeById(challengeById);
                setChallengeNutritionist(challengeNutritionist);

                var grouped = [],
                  ignore = {},
                  firstRound = {};
                data.map((d) => {
                  if (d.previousRounds) {
                    d.rounds = d.previousRounds.length + 1;
                    d.previousRounds.map((id, i) => {
                      ignore[id] = true;

                      if (i === d.previousRounds.length - 1)
                        firstRound[id] = true;
                    });
                    d.roundsIds = [d.key].concat(d.previousRounds);
                  }

                  if (firstRound[d.key]) {
                    d.rounds = 1;
                    d.roundsIds = [d.key];
                  }
                });

                setIgnore(ignore);

                setChallenges(data);
                setChallengesLoading(false);

                var storageChallenge = localStorage.getItem("challengeId");
                if (
                  storageChallenge &&
                  data.filter((c) => c.key === storageChallenge).length
                ) {
                  var d = data.filter((c) => c.key === storageChallenge)[0];
                  var rounds = data.filter(
                    (c) => c.roundsIds && c.roundsIds.includes(d.key)
                  );
                  setRounds(rounds.length ? rounds[0].roundsIds : false);
                  setSelectedChallenge(d.key);
                } else if (data.length) {
                  setRounds(data[0].roundsIds);
                  setSelectedChallenge(data[0].key);
                  localStorage.setItem("challengeId", selectedChallenge);
                }
              },
              (error) => {
                console.log("error", error);
              }
            );
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  function getCollections() {
    setPaymentsLoading(true);

    //challenge users - to figure out who is in without payments
    const subscribeUsers = firestore("users")
      .where("joinedChallengeIds", "array-contains", selectedChallenge)
      .onSnapshot(function (snapshot) {
        const users = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        var manualTxs = [];

        users /*.filter(d => d.refunded)*/
          .map((d) => {
            manualTxs.push({
              date: "",
              userName: d.name,
              userId: d.id,
              currency: "",
              amount: 0,
              gateway: "other",
              commission: 0,
              net: 0,
            });
          });

        setManualTxs(manualTxs);
      });

    const subscribeBundles = firestore("payments") //.orderBy('datetime', 'asc')
      .where("rounds", ">", 1)
      .where("roundsJoined", "array-contains", selectedChallenge)
      .onSnapshot(function (snapshot) {
        const payments = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        var bundles = [];

        payments
          .filter(
            (d) =>
              !d.declined &&
              !d.refunded &&
              d.roundsJoined[0] !== selectedChallenge
          )
          .map((d) => {
            bundles.push({
              date: d.datetime,
              userName: d.userName,
              userId: d.userId,
              currency: "",
              amount: 0,
              gateway: "bundle",
              commission: 0,
              net: 0,
            });
          });

        setBundles(bundles);
      });

    const subscribePayments = firestore("payments") //.orderBy('datetime', 'asc')
      .where("challengeId", "==", selectedChallenge)
      //.where('datetime', '>=', moment('2020-08-01').format())
      //.where('datetime', '<=', moment('2020-09-01').format())
      .onSnapshot(function (snapshot) {
        //.get().then(function(snapshot){

        const payments = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        var total = 0;
        var nPayments = 0;
        var commission = 0;
        var paymentsByDay = {};
        var paymentsByChallenge = {};
        var paymentsByNutritionist = {};

        var acceptPayments = 0;
        var stripePayments = 0;
        var vodafonePayments = 0;
        var manualPayments = 0;
        var acceptPaymentsEGP = 0;
        var stripePaymentsEGP = 0;
        var vodafonePaymentsEGP = 0;
        var manualPaymentsEGP = 0;
        var acceptPaymentsCount = 0;
        var stripePaymentsCount = 0;
        var vodafonePaymentsCount = 0;
        var manualPaymentsCount = 0;
        var userIds = {};

        var transactions = [];

        payments
          .filter((d) => !d.declined && !d.refunded)
          .map((d) => {
            var isAfter2024 =
              (Boolean(d.months) && moment(d.datetime).isAfter("2024-01-01")) ||
              moment(challengeById[d.challengeId].date).isAfter("2023-12-31"); // After 1/1/2024 system will breakdown the right exact amount of commission to each provider instead of taking 10% from all providers
            if (!challengeById[d.challengeId]) return;
            const challenge =
              challengeById[d.challengeId].name +
              (challengeById[d.challengeId].previousRounds
                ? " - round " +
                  (challengeById[d.challengeId].previousRounds.length + 1)
                : "");
            const date = moment(d.datetime).format(
              monthly ? formatMonth : format
            );

            if (!paymentsByChallenge[challenge])
              paymentsByChallenge[challenge] = {};
            if (!paymentsByChallenge[challenge][date])
              paymentsByChallenge[challenge][date] = { users: 0, amount: 0 };

            //fix for ramadan challenge
            if (d.challengeId === "zNqJyCWb8ztMJLkt6zWy") d.amount = 300;

            if (uniqueUsers) {
              if (!userIds[d.userId])
                paymentsByChallenge[challenge][date].users++;
              userIds[d.userId] = true;
            } else paymentsByChallenge[challenge][date].users++;
            paymentsByChallenge[challenge][date].amount += d.amount;

            if (
              !paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ]
            )
              paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ] = {
                amount: 0,
                nutritionistName: challengeById[d.challengeId].nutritionistName,
              };
            paymentsByNutritionist[
              challengeById[d.challengeId].nutritionistId
            ].amount += d.amount;

            const appleCommission = { old: 0.32, new: 0.16 }[
              moment(d.datetime).isBefore("2021-01-01") ? "old" : "new"
            ];

            const dollarRate = getDollarAtTime(d.datetime);
            /*if (d.type == "credits") {
							const amt = d.amount * (16.67 / dollarRate); //1:1 credits to usd
							total += amt;
							commission += appleCommission * amt;
						} else if (d.provider == "stripe") {
							const amt = d.currency === "USD" ? d.amount : d.amount / dollarRate;
							total += amt;
							commission += 0.029 * amt + 0.3;
						} else if (d.provider == "manual") {
							const amt = d.currency === "USD" ? d.amount : d.amount / dollarRate;
							total += amt;
							commission += 0;
						} else if (d.provider == "vodafone") {

							const amt = d.currency === "USD" ? d.amount : d.amount / dollarRate;
							total += amt;
							commission += 0;
						}
						else{

							console.error('undefined provider', d)
						}*/

            var amount = d.amount;
            if (d.type == "credits") amount *= 16.67 / dollarRate;
            //1:1 credits to usd 	//won't be there anymore
            else if (d.currency !== "USD") amount /= dollarRate;
            total += amount;
            commission += isAfter2024
              ? d.type === "credit"
                ? appleCommission * amount
                : d.provider === "stripe"
                ? 0.06 * amount
                : d.provider === "accept"
                ? 0.06 * amount
                : d.provider === "vodafone"
                ? 0.01 * amount
                : d.provider === "manual"
                ? 0.01 * amount
                : 0
              : 0.1 * amount;

            nPayments++;

            if (d.provider === "accept") acceptPayments += 0.96 * amount;
            if (d.provider === "stripe") stripePayments += 0.94 * amount;
            if (d.provider === "vodafone") vodafonePayments += 0.99 * amount;
            if (d.provider === "manual") manualPayments += 0.99 * amount;
            if (d.provider === "accept")
              acceptPaymentsEGP += 0.96 * amount * dollarRate;
            if (d.provider === "stripe")
              stripePaymentsEGP += 0.94 * amount * dollarRate;
            if (d.provider === "vodafone")
              vodafonePaymentsEGP += 0.99 * amount * dollarRate;
            if (d.provider === "manual")
              manualPaymentsEGP += 0.99 * amount * dollarRate;
            if (d.provider === "accept") acceptPaymentsCount++;
            if (d.provider === "stripe") stripePaymentsCount++;
            if (d.provider === "vodafone") vodafonePaymentsCount++;
            if (d.provider === "manual") manualPaymentsCount++;

            transactions.push({
              date: d.datetime,
              userName: d.userName,
              userId: d.userId,
              currency: d.type === "credits" ? "USD" : d.currency,
              amount: d.amount,
              gateway: d.provider,
              commission: isAfter2024
                ? d.type === "credits"
                  ? appleCommission * d.amount
                  : d.provider === "stripe"
                  ? 0.06 * d.amount
                  : d.provider === "accept"
                  ? 0.06 * d.amount
                  : d.provider === "vodafone"
                  ? 0.01 * d.amount
                  : d.provider === "manual"
                  ? 0.01 * d.amount
                  : 0
                : 0.1 * d.amount,
              net: isAfter2024
                ? d.amount -
                  (d.type === "credits"
                    ? appleCommission * d.amount
                    : d.provider === "stripe"
                    ? 0.06 * d.amount
                    : d.provider === "accept"
                    ? 0.06 * d.amount
                    : d.provider === "vodafone"
                    ? 0.01 * d.amount
                    : d.provider === "manual"
                    ? 0.01 * d.amount
                    : 0)
                : 0.9 * d.amount,
              src: d.src,
              rounds: d.rounds,
            });
          });

        payments
          .filter((d) => d.refunded)
          .map((d) => {
            nPayments++;
            var isAfter2024 =
              (Boolean(d.months) && moment(d.datetime).isAfter("2024-01-01")) ||
              moment(challengeById[d.challengeId].date).isAfter("2023-12-31"); // After 1/1/2024 system will breakdown the right exact amount of commission to each provider instead of taking 10% from all providers
            const appleCommission = { old: 0.32, new: 0.16 }[
              moment(d.datetime).isBefore("2021-01-01") ? "old" : "new"
            ];

            const dollarRate = getDollarAtTime(d.datetime);

            transactions.push({
              refunded: true,
              date: d.datetime,
              userName: d.userName,
              userId: d.userId,
              currency: d.type == "credits" ? "USD" : d.currency,
              amount: d.amount,
              gateway: d.provider,
              commission: isAfter2024
                ? d.type === "credits"
                  ? appleCommission * d.amount
                  : d.provider === "stripe"
                  ? 0.06 * d.amount
                  : d.provider === "accept"
                  ? 0.06 * d.amount
                  : d.provider === "vodafone"
                  ? 0.01 * d.amount
                  : d.provider === "manual"
                  ? 0.01 * d.amount
                  : 0
                : 0.1 * d.amount,
              net: isAfter2024
                ? d.amount -
                  (d.type === "credits"
                    ? appleCommission * d.amount
                    : d.provider === "stripe"
                    ? 0.06 * d.amount
                    : d.provider === "accept"
                    ? 0.06 * d.amount
                    : d.provider === "vodafone"
                    ? 0.01 * d.amount
                    : d.provider === "manual"
                    ? 0.01 * d.amount
                    : 0)
                : 0.9 * d.amount,
              src: d.src,
            });
          });

        setTransactions(
          _.orderBy(transactions, (d) => moment.utc(d.date).format(), "desc")
        );

        setTotal(total);
        setNet(total - commission);
        setnPayments(nPayments);
        setAcceptPayments(acceptPayments);
        setStripePayments(stripePayments);
        setVodafonePayments(vodafonePayments);
        setManualPayments(manualPayments);
        setAcceptPaymentsEGP(acceptPaymentsEGP);
        setStripePaymentsEGP(stripePaymentsEGP);
        setVodafonePaymentsEGP(vodafonePaymentsEGP);
        setManualPaymentsEGP(manualPaymentsEGP);
        setAcceptPaymentsCount(acceptPaymentsCount);
        setStripePaymentsCount(stripePaymentsCount);
        setVodafonePaymentsCount(vodafonePaymentsCount);
        setManualPaymentsCount(manualPaymentsCount);

        setPaymentsLoading(false);

        //for(var day in pointsByDay){

        //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
        //}

        //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

        //setDays(days);
      });

    return [subscribeUsers, subscribeBundles, subscribePayments];
  }

  function loadPayouts() {
    setPayoutsLoading(true);

    const subscribePayments = firestore("payments") //.orderBy('datetime', 'asc')
      //.where('challengeId', '==', selectedChallenge)
      //.where('datetime', '>', moment().subtract(6, "months").format())
      .where("datetime", ">", moment().subtract(1, "year").format())
      //.where('datetime', '<=', moment('2020-09-01').format())
      //.onSnapshot(function(snapshot){
      .get()
      .then(function (snapshot) {
        const payments = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        var total = 0;
        var nPayments = 0;
        var commission = 0;
        var paymentsByMonth = {};
        var paymentsByChallenge = {};
        var paymentsByNutritionist = {};

        var userIds = {};

        var transactions = [];

        payments
          .filter((d) => !d.declined && !d.refunded)
          .map((d) => {
            if (!challengeById[d.challengeId]) return;

            //const challenge = challengeById[d.challengeId].name + (challengeById[d.challengeId].previousRounds? ' - round '+(challengeById[d.challengeId].previousRounds.length+1):'')
            const date = moment(d.datetime).format(formatMonth);
            var isAfter2024 =
              (Boolean(d.months) && moment(d.datetime).isAfter("2024-01-01")) ||
              moment(challengeById[d.challengeId].date).isAfter("2023-12-31");

            const appleCommission = { old: 0.32, new: 0.16 }[
              moment(d.datetime).isBefore("2021-01-01") ? "old" : "new"
            ];

            const dollarRate = getDollarAtTime(d.datetime);

            var _amt2,
              commission2 = 0;
            if (d.type == "credits") {
              const amt = d.amount * (16.67 / dollarRate); //1:1 credits to usd
              total += amt;
              commission2 += appleCommission * amt;
              _amt2 = amt - appleCommission * amt;
            } else if (d.provider == "stripe") {
              const amt =
                d.currency === "USD" ? d.amount : d.amount / dollarRate;
              total += amt;
              commission2 += 0.029 * amt + 0.3;
              _amt2 = amt - (0.029 * amt + 0.3);
            } else if (d.provider == "manual") {
              const amt =
                d.currency === "USD" ? d.amount : d.amount / dollarRate;
              total += amt;
              commission2 += 0;
              _amt2 = amt - 0;
            } else if (d.provider == "vodafone") {
              const amt =
                d.currency === "USD" ? d.amount : d.amount / dollarRate;
              total += amt;
              commission2 += 0;
              _amt2 = amt - 0;
            } else {
              console.error("undefined provider", d);
            }

            var _amt3,
              commission3 = 0;
            if (isAfter2024) {
              if (d.type === "credits") {
                const amt = d.amount * (16.67 / dollarRate); //1:1 credits to usd
                total += amt;
                commission3 += appleCommission * amt;
                _amt3 = amt - appleCommission * amt;
              } else if (d.provider === "stripe") {
                const amt =
                  d.currency === "USD" ? d.amount : d.amount / dollarRate;
                total += amt;
                commission3 += 0.06 * amt;
                _amt3 = amt - 0.06 * amt;
              } else if (d.provider === "manual") {
                const amt =
                  d.currency === "USD" ? d.amount : d.amount / dollarRate;
                total += amt;
                commission3 += 0.01 * amt;
                _amt3 = amt - 0.01 * amt;
              } else if (d.provider === "vodafone") {
                const amt =
                  d.currency === "USD" ? d.amount : d.amount / dollarRate;
                total += amt;
                commission3 += 0.01 * amt;
                _amt3 = amt - 0.01 * amt;
              } else if (d.provider === "accept") {
                const amt =
                  d.currency === "USD" ? d.amount : d.amount / dollarRate;
                total += amt;
                commission3 += 0.06 * amt;
                _amt3 = amt - 0.06 * amt;
              } else {
                console.error("undefined provider", d);
              }
            }

            var amount = d.amount;
            if (d.type == "credits") amount *= 16.67 / dollarRate;
            //1:1 credits to usd 	//won't be there anymore
            else if (d.currency !== "USD") amount /= dollarRate;
            total += amount;
            var commission = 0.1 * amount; //global 10% commission
            var _amt = amount - commission;

            //show old payouts for a while
            if (moment(d.datetime).isBefore("2022-10-01")) _amt = _amt2;
            if (isAfter2024) _amt = _amt3;
            const oneToOne = Boolean(d.months);

            if (
              !paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ]
            )
              paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ] = {};
            if (
              !paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ][date]
            )
              paymentsByNutritionist[
                challengeById[d.challengeId].nutritionistId
              ][date] = {
                oneToOne: oneToOne,
                amount: 0,
                amountEGP: 0,
                date: date,
                nutritionistId: challengeById[d.challengeId].nutritionistId,
                nutritionistName: challengeById[d.challengeId].nutritionistName,
              };
            paymentsByNutritionist[challengeById[d.challengeId].nutritionistId][
              date
            ].amount +=
              !oneToOne &&
              hasCollaborator[challengeById[d.challengeId].nutritionistId]
                ? 0.65 * _amt
                : (challengeById[d.challengeId].nutritionistId ===
                  "wAHcTEeg2Ksph5Jm0mQm"
                    ? 1
                    : 0.75) * _amt;
            paymentsByNutritionist[challengeById[d.challengeId].nutritionistId][
              date
            ].amountEGP +=
              (!oneToOne &&
              hasCollaborator[challengeById[d.challengeId].nutritionistId]
                ? 0.65 * _amt
                : (challengeById[d.challengeId].nutritionistId ===
                  "wAHcTEeg2Ksph5Jm0mQm"
                    ? 1
                    : 0.75) * _amt) * dollarRate;

            //add to collaborators
            if (hasCollaborator[challengeById[d.challengeId].nutritionistId]) {
              collaborators[challengeById[d.challengeId].nutritionistId].map(
                (c) => {
                  if (!paymentsByNutritionist[c])
                    paymentsByNutritionist[c] = {};
                  if (!paymentsByNutritionist[c][date])
                    paymentsByNutritionist[c][date] = {
                      oneToOne: Boolean(d.months),
                      amount: 0,
                      amountEGP: 0,
                      date: date,
                      nutritionistId: c,
                      nutritionistName: collaboratorName[c],
                    };
                  if (!collaboratorName[c]) console.log(c);
                  paymentsByNutritionist[c][date].amount += 0.1 * _amt;
                  paymentsByNutritionist[c][date].amountEGP +=
                    0.1 * _amt * dollarRate;
                }
              );
            }
          });

        var payouts = [];
        for (var nutritionist in paymentsByNutritionist)
          for (var date in paymentsByNutritionist[nutritionist]) {
            paymentsByNutritionist[nutritionist][date].due = moment(
              paymentsByNutritionist[nutritionist][date].date,
              formatMonth
            )
              .add(1, "month")
              .add(19, "day");
            payouts.push(paymentsByNutritionist[nutritionist][date]);
          }

        setPayouts(
          _.orderBy(payouts, (d) => moment(d.date, formatMonth), "desc")
        );

        setPayoutsLoading(false);

        //for(var day in pointsByDay){

        //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
        //}

        //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

        //setDays(days);
      });

    return;
  }

  async function getUserData() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const querySnapshot = await firestore("users").doc(userDetails.id).get();

    setFormValue({
      name: querySnapshot.data().bankAccount
        ? querySnapshot.data().bankAccount.name
        : "",
      bank: querySnapshot.data().bankAccount
        ? querySnapshot.data().bankAccount.bank
        : "",
      account: querySnapshot.data().bankAccount
        ? querySnapshot.data().bankAccount.account
        : "",
      swift: querySnapshot.data().bankAccount
        ? querySnapshot.data().bankAccount.swift
        : "",
    });
  }

  async function submitForm(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!editMode) return setEditMode(!editMode);
    setEditMode(!editMode);

    setIsSubmitLoader(true);
    const dataToSubmit = {};
    if (formValue.name) dataToSubmit.name = formValue.name;
    if (formValue.bank) dataToSubmit.bank = formValue.bank;
    if (formValue.account) dataToSubmit.account = formValue.account;
    if (formValue.swift) dataToSubmit.swift = formValue.swift;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    await firestore("users")
      .doc(userDetails.id)
      .update({ bankAccount: dataToSubmit });
    setIsSubmitLoader(false);
  }

  function handleSelect(key) {
    if (key === "payouts") loadPayouts();
  }

  function processPayout(payout, bankAccount) {
    var obj = Object.assign({}, payout, bankAccount);
    obj.due = obj.due.format("DD-MM-YYYY");
    obj.datetime = moment().format();
    firestore("payouts").add(obj);
    setShowPayout(false);
  }

  function sortBy(item) {
    var order = "asc";
    if (sortItem === item) order = "desc";
    if (sortOrder === "desc") item = null;

    setSortOrder(order);
    setSortItem(item);
  }

  return (
    <div className="billing-page">
      <Header header="Billing" />

      <hr />

      {/* <Breadcrumb className="pb-3" style={{height: '7em'}}>
            <Breadcrumb.Item active>Billing</Breadcrumb.Item>
        </Breadcrumb> */}
      <Card>
        <Tabs
          className="tabs"
          defaultActiveKey="payments"
          style={{ width: "100%" }}
          onSelect={handleSelect}
        >
          <Tab className="tab" eventKey="payments" title="All payments">
            {false /*challengesLoading*/ ? (
              <div>
                <Spinner animation="border" size={"lg"} role="status" />
              </div>
            ) : challenges.length ? (
              <Card id="challenge-card" className="mt-5">
                <Row>
                  <Col xl={8} sm={12}>
                    <Row>
                      <Col className="selectWrapper" xs={12}>
                        <h4>One to One</h4>
                        <Form.Control
                          required
                          as="select"
                          placeholder="Select Challenge"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "auto",
                            display: "inline-block",
                          }}
                          value={
                            ignore[selectedChallenge]
                              ? challenges.filter(
                                  (c) =>
                                    c.previousRounds &&
                                    c.previousRounds.includes(selectedChallenge)
                                )[0].key
                              : selectedChallenge
                          }
                          onChange={(e) => {
                            challenges
                              .filter((d) => d.oneToOne)
                              .map((c) => {
                                if (c.key == e.target.value) {
                                  setRounds(c.roundsIds);
                                  setChallenge(c);
                                }
                              });
                            setSelectedChallenge(e.target.value);
                            localStorage.setItem("challengeId", e.target.value);
                          }}
                        >
                          <option>select Nutritionist</option>
                          {challenges
                            .filter((d) => d.oneToOne)
                            .filter((c) => !ignore[c.key])
                            .map((obj, i) => {
                              return (
                                <option
                                  value={obj.key}
                                  key={obj.key}
                                  //hidden={ignore[obj.key]}
                                >
                                  {obj.nutritionistName}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="selectWrapper" xs={9}>
                        <h4>Challenge</h4>
                        <Form.Control
                          required
                          as="select"
                          placeholder="Select Challenge"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            display: "inline-block",
                            height: "auto",
                          }}
                          value={
                            ignore[selectedChallenge]
                              ? challenges.filter(
                                  (c) =>
                                    c.previousRounds &&
                                    c.previousRounds.includes(selectedChallenge)
                                )[0].key
                              : selectedChallenge
                          }
                          onChange={(e) => {
                            challenges
                              .filter((d) => !d.oneToOne)
                              .map((c) => {
                                if (c.key == e.target.value) {
                                  setRounds(c.roundsIds);
                                  setChallenge(c);
                                }
                              });
                            setSelectedChallenge(e.target.value);
                            localStorage.setItem("challengeId", e.target.value);
                          }}
                        >
                          <option>Select Challenge</option>
                          {challenges
                            .filter((d) => !d.oneToOne)
                            .filter((c) => !ignore[c.key])
                            .map((obj, i) => {
                              return (
                                <option
                                  value={obj.key}
                                  key={obj.key}
                                  //hidden={ignore[obj.key]}
                                >
                                  {obj.nutritionistName} -{" "}
                                  {obj.name.replace(/Round(.*)- /, "")}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                      {rounds && (
                        <Col xs={3}>
                          <h4>Round</h4>
                          <Form.Control
                            required
                            as="select"
                            placeholder="Select Round"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              display: "inline-block",
                              height: "auto",
                            }}
                            value={selectedChallenge}
                            onChange={(e) => {
                              challenges
                                .filter((d) => !d.oneToOne)
                                .map((c) => {
                                  if (c.key == e.target.value) setChallenge(c);
                                });
                              setSelectedChallenge(e.target.value);
                              localStorage.setItem(
                                "challengeId",
                                e.target.value
                              );
                            }}
                          >
                            {challenges
                              .filter((d) => !d.oneToOne)
                              .filter((d) => rounds.includes(d.key))
                              .map((obj, i) => {
                                return (
                                  <option value={obj.key} key={obj.key}>
                                    {obj.previousRounds
                                      ? "round " +
                                        (obj.previousRounds.length + 1)
                                      : "round 1"}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col
                    xl={4}
                    sm={12}
                    style={{
                      color: "#ddd",
                      paddingLeft: "0",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{ display: "inline-block", paddingLeft: "30px" }}
                    >
                      sources
                    </div>
                    <div
                      className="donut"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <Chart
                        options={sources.options}
                        series={sources.series}
                        type="donut"
                        width="100%"
                        height="200"
                      />
                    </div>
                  </Col>
                </Row>
                <div id="billing" className="h-100">
                  {paymentsLoading ? (
                    <div className="loader text-center">
                      <Spinner animation="border" size={"sm"} role="status" />
                    </div>
                  ) : (
                    <table>
                      <tbody>
                        <tr>
                          <td className="key">
                            <span>no of payments</span>
                            <div className="value">
                              {numberWithCommas(+nPayments.toFixed(0))}
                            </div>
                          </td>
                        </tr>

                        {transactions.filter((d) => d.rounds === 2).length >
                          0 && (
                          <tr>
                            <td className="key bundle">
                              2 rounds bundles
                              <div className="value">
                                {
                                  transactions.filter((d) => d.rounds === 2)
                                    .length
                                }
                              </div>
                            </td>
                          </tr>
                        )}

                        {transactions.filter((d) => d.rounds === 3).length >
                          0 && (
                          <tr>
                            <td className="key bundle">
                              3 rounds bundles
                              <div className="value">
                                {
                                  transactions.filter((d) => d.rounds === 3)
                                    .length
                                }
                              </div>
                            </td>
                          </tr>
                        )}

                        {transactions.filter((d) => d.rounds === 6).length >
                          0 && (
                          <tr>
                            <td className="key bundle">
                              6 rounds bundles
                              <div className="value">
                                {
                                  transactions.filter((d) => d.rounds === 6)
                                    .length
                                }
                              </div>
                            </td>
                          </tr>
                        )}

                        {transactions.filter((d) => d.rounds === 12).length >
                          0 && (
                          <tr>
                            <td className="key bundle">
                              12 rounds bundles
                              <div className="value">
                                {
                                  transactions.filter((d) => d.rounds === 12)
                                    .length
                                }
                              </div>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="key">
                            <span>total</span>
                            <div className="value">
                              <em>$</em>
                              {numberWithCommas(+total.toFixed(0))}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="key">
                            <span>after payment gateway fees</span>

                            <div className="value">
                              <em>$</em>
                              {numberWithCommas(+net.toFixed(0))}
                            </div>
                          </td>
                        </tr>
                        {challengeById[selectedChallenge] &&
                        !challengeById[selectedChallenge].oneToOne &&
                        hasCollaborator[
                          challengeById[selectedChallenge].nutritionistId
                        ] ? (
                          <tr>
                            <td className="key">
                              <span>nutritionist</span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+(net * 0.65).toFixed(0))}
                              </div>
                            </td>
                          </tr>
                        ) : challengeById[selectedChallenge] &&
                          challengeById[selectedChallenge].nutritionistId ===
                            "wAHcTEeg2Ksph5Jm0mQm" ? null : (
                          <tr>
                            <td className="key">
                              <span>nutritionist</span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+(net * 0.75).toFixed(0))}
                              </div>
                            </td>
                          </tr>
                        )}
                        {challengeById[selectedChallenge] &&
                        !challengeById[selectedChallenge].oneToOne &&
                        hasCollaborator[
                          challengeById[selectedChallenge].nutritionistId
                        ] ? (
                          <tr>
                            <td className="key">
                              <span>collaborator</span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+(net * 0.1).toFixed(0))}
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        {challengeById[selectedChallenge] &&
                        challengeById[selectedChallenge].nutritionistId ===
                          "wAHcTEeg2Ksph5Jm0mQm" ? null : (
                          <tr>
                            <td className="key">
                              <span>welnes</span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+(net * 0.2).toFixed(0))}
                              </div>
                            </td>
                          </tr>
                        )}
                        {true /*isAdmin*/ && (
                          <tr>
                            <td className="key">
                              <span>
                                stripe{" "}
                                <em style={{ fontWeight: "normal" }}>
                                  ({stripePaymentsCount})
                                </em>
                              </span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+stripePayments.toFixed(0))}
                              </div>
                              <div
                                className="value"
                                style={{ fontSize: "11px" }}
                              >
                                {numberWithCommas(
                                  +stripePaymentsEGP.toFixed(0)
                                )}{" "}
                                EGP
                              </div>
                            </td>
                          </tr>
                        )}
                        {true /*isAdmin*/ && (
                          <tr>
                            <td className="key">
                              <span>
                                accept{" "}
                                <em style={{ fontWeight: "normal" }}>
                                  ({acceptPaymentsCount})
                                </em>
                              </span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+acceptPayments.toFixed(0))}
                              </div>
                              <div
                                className="value"
                                style={{ fontSize: "11px" }}
                              >
                                {numberWithCommas(
                                  +acceptPaymentsEGP.toFixed(0)
                                )}{" "}
                                EGP
                              </div>
                            </td>
                          </tr>
                        )}
                        {
                          //manualTxs.filter(d => !transactions.map((d) => d.userId).includes(d.userId) && !bundles.map((d) => d.userId).includes(d.userId)).length ? (
                          true /*isAdmin*/ /*transactions.filter((d) => d.gateway == "vodafone").length*/ ? (
                            <tr>
                              <td className="key manual">
                                {/*vodafone
															<div className="value">
																{transactions.filter((d) => d.gateway == "vodafone").length}
															</div>*/}
                                <span>
                                  vodafone{" "}
                                  <em style={{ fontWeight: "normal" }}>
                                    ({vodafonePaymentsCount})
                                  </em>
                                </span>
                                <div className="value">
                                  <em>$</em>
                                  {numberWithCommas(
                                    +vodafonePayments.toFixed(0)
                                  )}
                                </div>
                                <div
                                  className="value"
                                  style={{ fontSize: "11px" }}
                                >
                                  {numberWithCommas(
                                    +vodafonePaymentsEGP.toFixed(0)
                                  )}{" "}
                                  EGP
                                </div>
                              </td>
                            </tr>
                          ) : null
                        }
                        {true /*isAdmin*/ && (
                          <tr>
                            <td className="key">
                              <span>
                                manual{" "}
                                <em style={{ fontWeight: "normal" }}>
                                  ({manualPaymentsCount})
                                </em>
                              </span>
                              <div className="value">
                                <em>$</em>
                                {numberWithCommas(+manualPayments.toFixed(0))}
                              </div>
                              <div
                                className="value"
                                style={{ fontSize: "11px" }}
                              >
                                {numberWithCommas(
                                  +manualPaymentsEGP.toFixed(0)
                                )}{" "}
                                EGP
                              </div>
                            </td>
                          </tr>
                        )}

                        {bundles.length > 0 && (
                          <tr>
                            <td className="key bundles">
                              previous bundles
                              <div className="value">{bundles.length}</div>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="key other">
                            without payment
                            <div className="value">
                              {
                                transactions
                                  .concat(bundles)
                                  .concat(
                                    manualTxs.filter(
                                      (d) =>
                                        !transactions
                                          .map((d) => d.userId)
                                          .includes(d.userId) &&
                                        !bundles
                                          .map((d) => d.userId)
                                          .includes(d.userId)
                                    )
                                  )
                                  .filter((d) => d.gateway === "other").length
                              }
                            </div>
                          </td>
                        </tr>

                        <tr>
                          {transactions.filter((d) => d.refunded).length ? (
                            <>
                              <td className="key refunds">
                                <span>refunds</span>
                                <div className="value">
                                  {
                                    transactions.filter((d) => d.refunded)
                                      .length
                                  }
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </Card>
            ) : (
              <div></div>
            )}
            <div style={{ overflow: "auto" }}>
              {!paymentsLoading && (
                <table
                  id="transactions"
                  className="table"
                  style={{ marginTop: "5em" }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>User</th>
                      <th>Amount</th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          sortBy("gateway");
                        }}
                      >
                        Gateway
                      </th>
                      {/* <th>Gateway fees</th> */}
                      <th>Total (After fees)</th>
                      <th>Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(sortItem
                      ? _.orderBy(
                          transactions,
                          (d) =>
                            isAdmin
                              ? d[sortItem]
                              : d[sortItem]
                                  .replace("apple", "regular")
                                  .replace("stripe", "USD")
                                  .replace("accept", "EGP"),
                          sortOrder
                        )
                      : transactions
                    )
                      .concat(bundles)
                      .concat(
                        manualTxs.filter(
                          (d) =>
                            !transactions
                              .map((d) => d.userId)
                              .includes(d.userId) &&
                            !bundles.map((d) => d.userId).includes(d.userId)
                        )
                      )
                      .map((d) => (
                        <tr
                          className={d.refunded && "refunded"}
                          style={
                            d.gateway === "other" ? { color: "steelblue" } : {}
                          }
                        >
                          <td>
                            {d.date.split("T")[0]}
                            {d.gateway === "other" && "other"}
                            {d.gateway === "other" && (
                              <FontAwesomeIcon
                                icon={faDoorOpen}
                                size="1x"
                                className="mr-2"
                                style={{ float: "left" }}
                              />
                            )}
                          </td>
                          <td>
                            {d.userName}
                            {d.refunded && (
                              <FontAwesomeIcon
                                icon={faUndo}
                                size="1x"
                                className="mr-2"
                                style={{ float: "right" }}
                              />
                            )}
                          </td>
                          <td>
                            {d.amount.toFixed(2)} {d.currency}
                          </td>
                          <td>
                            {d.gateway === "other"
                              ? "-"
                              : isAdmin
                              ? d.gateway
                              : d.gateway
                                  .replace("apple", "regular")
                                  .replace("stripe", "USD")
                                  .replace("accept", "EGP")}
                          </td>
                          {/* <td>
                            {d.commission.toFixed(2)} {d.currency}
                          </td> */}
                          <td>
                            {d.net.toFixed(2)} {d.currency}
                          </td>
                          <td>{d.src}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </Tab>
          <Tab className="tab" eventKey="payouts" title="Proceeds">
            <div id="proceeds" className="pt-2 h-100 align-self-center">
              <div>
                <Form className="form mt-5" onSubmit={(e) => submitForm(e)}>
                  <div className={"bank-card" + (editMode ? "" : " read-only")}>
                    <img alt="" src={bankPng} style={{ float: "right" }} />
                    <h3 className="justify-content-between">Bank Details</h3>

                    <br />

                    <Form.Group>
                      <Form.Label>Name:</Form.Label>
                      <Form.Control
                        placeholder=""
                        readOnly={!editMode}
                        value={formValue.name}
                        onChange={(e) => {
                          const formValueClone = _.clone(formValue);
                          formValueClone.name = e.target.value;
                          setFormValue(formValueClone);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Bank:</Form.Label>
                      <Form.Control
                        placeholder=""
                        readOnly={!editMode}
                        value={formValue.bank}
                        onChange={(e) => {
                          const formValueClone = _.clone(formValue);
                          formValueClone.bank = e.target.value;
                          setFormValue(formValueClone);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Account no/IBAN:</Form.Label>
                      <Form.Control
                        placeholder=""
                        readOnly={!editMode}
                        type={editMode ? "text" : "password"}
                        value={formValue.account}
                        onChange={(e) => {
                          const formValueClone = _.clone(formValue);
                          formValueClone.account = e.target.value;
                          setFormValue(formValueClone);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Swift code:</Form.Label>
                      <Form.Control
                        placeholder=""
                        readOnly={!editMode}
                        type={editMode ? "text" : "password"}
                        value={formValue.swift}
                        onChange={(e) => {
                          const formValueClone = _.clone(formValue);
                          formValueClone.swift = e.target.value;
                          setFormValue(formValueClone);
                        }}
                      />
                    </Form.Group>

                    <Button type="submit" variant="light" className="edit-save">
                      {isSubmitLoader ? (
                        <Spinner animation="border" size={"sm"} role="status" />
                      ) : (
                        <div>
                          <FontAwesomeIcon
                            icon={editMode ? faSave : faEdit}
                            size="1x"
                            className="mr-2"
                          />
                          {editMode ? "Save" : "Edit"}
                        </div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            {isAdmin && (
              <div>
                <span
                  style={{
                    fontSize: ".9em",
                    width: "100%",
                    textAlign: "right",

                    paddingTop: "3em",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div className="d-flex flex-column align-items-start">
                    <div style={{ color: "#383838", fontSize: "0.9rem" }}>
                      <Button
                        style={{
                          height: "15px",
                          width: "15px",
                          padding: "0",
                          border: "0",
                          borderRadius: "50%",
                          backgroundColor: showAll ? "#11BD87" : "#d1d3d1",
                        }}
                        type="radio"
                        onClick={() => {
                          setShowAll(!showAll);
                        }}
                      ></Button>{" "}
                      show payments for All nutritionists
                    </div>
                    <div style={{ marginLeft: "16px", color: "#a8a8a8" }}>
                      *you are an Admin
                    </div>
                  </div>
                </span>
              </div>
            )}

            {payoutsLoading ? (
              <div className="loader text-center" style={{ paddingTop: "3em" }}>
                <Spinner animation="border" size={"sm"} role="status" />
              </div>
            ) : (
              <table
                id="transactions"
                className="table"
                style={{ marginTop: "3em" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    {showAll && <th>Nutritionist</th>}
                    <th>Amount (USD)</th>
                    <th>Amount (EGP)</th>
                    <th>Status</th>
                    <th>Due</th>
                  </tr>
                </thead>
                <tbody>
                  {(showAll
                    ? payouts
                    : payouts.filter(
                        (d) =>
                          d.nutritionistId === (isAdmin ? user.id : admin.id)
                      )
                  ).map((d) => (
                    <tr
                      style={{
                        cursor:
                          payoutDetails[d.nutritionistId + d.date] || !isAdmin
                            ? "default"
                            : "pointer",
                      }}
                      onClick={() => {
                        !payoutDetails[d.nutritionistId + d.date] &&
                          isAdmin &&
                          setShowPayout(d);
                      }}
                    >
                      <td style={{ fontWeight: "500", color: "#909090" }}>
                        {d.date}
                      </td>
                      {showAll && <td>{d.nutritionistName}</td>}
                      <td style={{ fontWeight: "bold" }}>
                        ${numberWithCommas(+d.amount.toFixed(0))}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {numberWithCommas(+d.amountEGP.toFixed(0))} EGP
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          color: payoutDetails[d.nutritionistId + d.date]
                            ? "#43A9EE"
                            : "#EEA943",
                        }}
                      >
                        {payoutDetails[d.nutritionistId + d.date]
                          ? "Processed"
                          : "Due"}
                      </td>
                      <td>{d.due.format("DD-MM-YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Tab>
        </Tabs>
      </Card>
      <Modal show={showPayout} onHide={() => setShowPayout(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Nutritionist <b>{showPayout.nutritionistName}</b>
          </div>
          <div>
            Date <b>{showPayout.date}</b>
          </div>
          <div>
            Amount{" "}
            <b>${numberWithCommas(+(showPayout.amount || 0).toFixed(0))}</b>
          </div>
          <div>
            Due <b>{showPayout.due && showPayout.due.format("DD-MM-YYYY")}</b>
          </div>
          <div style={{ margin: "3em 0 1em", fontSize: ".9em", opacity: 0.5 }}>
            Bank Account details
          </div>
          <div>
            Name{" "}
            <b>
              {nutritionistBankAccount[showPayout.nutritionistId] &&
                nutritionistBankAccount[showPayout.nutritionistId].name}
            </b>
          </div>
          <div>
            Bank{" "}
            <b>
              {nutritionistBankAccount[showPayout.nutritionistId] &&
                nutritionistBankAccount[showPayout.nutritionistId].bank}
            </b>
          </div>
          <div>
            Account{" "}
            <b>
              {nutritionistBankAccount[showPayout.nutritionistId] &&
                nutritionistBankAccount[showPayout.nutritionistId].account}
            </b>
          </div>
          <div>
            Swift{" "}
            <b>
              {nutritionistBankAccount[showPayout.nutritionistId] &&
                nutritionistBankAccount[showPayout.nutritionistId].swift}
            </b>
          </div>
          <Button
            variant="dark"
            onClick={() => {
              processPayout(
                showPayout,
                nutritionistBankAccount[showPayout.nutritionistId]
              );
            }}
            className="float-right"
          >
            <FontAwesomeIcon icon={faCheckSquare} size="1x" className="mr-2" />
            Processed
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
