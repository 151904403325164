/** @format */

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Spinner,
  Breadcrumb,
  ToggleButton,
} from "react-bootstrap";
import AddRecipe from "../../components/AddRecipe";
import { numberWithCommas } from "../../functions/helpers";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faFilter,
  faArrowUp,
  faArrowDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";

import { firestore } from "../../services/firebase";
import AddMealPlan from "../../components/AddMealPlan";
import AddMealPlan2 from "../../components/AddMealPlan2";
import RecipeFilter from "../../components/RecipeFilter";
import * as _ from "lodash";
import DeleteAlert from "../../components/DeleteAlert";
import moment from "moment";
import RecipeCard from "../../components/RecipeCard";
import "./MealPlans.scss";
import { Header } from "../../components/Header";
library.add(faPlus, faFilter);

export function MealPlansPage() {
  let numberOfMealPlans = [];
  const [nItems, setNItems] = useState(20);
  const [collaborators, setCollaborators] = useState({});
  const [show, setShow] = useState(false);
  const [recipesLoader, setRecipesLoader] = useState(false);
  const [mealPlansLoader, setMealPlansLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedSort, setSelectedSort] = useState({});
  const [searchKey, setSearchKey] = useState();
  const [docId, setDocId] = useState("");
  const [recipe, setRecipe] = useState(1);
  const [sort, setSort] = useState({
    field: "name",
    direction: "asc",
  });

  const tableHeaders = [
    {
      name: "Name",
      filterField: "name",
      //}, {
      //    name: '',
      //    filterField: 'none',
    },
    {
      name: "Date",
      filterField: "datetime",
      //}, {
      //    name: 'Type',
      //    filterField: 'type',
    },
    {
      name: "Calories",
      filterField: "Calories",
      //}, {
      //    name: 'Actions',
      //    filterField: 'none',
    },
  ];
  const [selectedSortData, setSelectedSortData] = useState(tableHeaders);

  const [ignore, setIgnore] = useState({});
  const [variations, setVariations] = useState(false);
  const [recipesByName, setRecipesByName] = useState({});
  const [mealPlans, setMealPlans] = useState([]);
  const [mealPlanData, setMealPlanData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [showMealPlan, setShowMealPlan] = useState(false);
  const [showMealPlan2, setShowMealPlan2] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [items, setItems] = useState();
  const [allRecipes, setAllRecipes] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeData, setRecipeData] = useState("");
  const [recipeTypes, setRecipeTypes] = useState([]);
  const [recipeTags, setRecipeTags] = useState(["Affordable", "Gourmet"]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [recipePlans, setRecipePlans] = useState({});
  const [welnes, setWelnes] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRecipeData("");
  };
  const handleShow = () => setShow(true);
  const handleCloseMealPlan = () => {
    setMealPlanData({});
    setShowMealPlan(false);
    setShowMealPlan2(false);
  };

  useEffect(() => {
    const onScroll = (e) => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        // you're at the bottom of the page
        setNItems((nItems) => nItems + 20);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    getCollaborators();
    getRecipeTypes();
    const mealPlanSubscription = getMealPlans();
    return () => {
      mealPlanSubscription();
    };
  }, []);

  useEffect(() => {
    const recipesSubscription = getRecipes(welnes);
    return () => {
      recipesSubscription();
    };
  }, [filterData, welnes]);

  useEffect(() => {
    setTimeout(getFood, 0);
    return () => {};
  }, []);

  useEffect(() => {
    if (variations.length) {
      //modify opened recipe variations

      var _variations = recipes.filter((r) => r.name === variations[0].name);
      if (_variations.length > 1) setVariations(_variations);
      else setVariations(false);
    }

    return () => {};
  }, [recipes]);

  async function getCollaborators() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails.collaborators && userDetails.collaborators.length) {
      const collaborators = {};

      const docs = await firestore("users")
        .where("id", "in", userDetails.collaborators)
        .get();
      docs.forEach((doc) => {
        collaborators[doc.id] = doc.data();
      });

      setCollaborators(collaborators);
    }
  }

  async function getRecipeTypes() {
    const recipeTypesDoc = await firestore("constants")
      .doc("recipeTypes")
      .get();
    setRecipeTypes(recipeTypesDoc.data().value);
  }

  function getFood() {
    firestore("food")
      .get()
      .then(function (snapshot) {
        const items = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];
        setItems(_.sortBy(items, ["category", "food"]));
      });
  }

  function getRecipes(welnes) {
    setRecipesLoader(true);
    const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
    let recipeRef = firestore("recipes")
      //.where('nutritionistId', '==', welnes? 'welnes' : userDetailsStorage.id);
      .where("nutritionistId", "in", [userDetailsStorage.id, "welnes"]);
    //.where('nutritionistId', 'in', [userDetailsStorage.id].concat(userDetailsStorage.collaborators||[]));
    if (filterData.day) {
      recipeRef = recipeRef.where("day", "==", Number(filterData.day));
    }
    if (filterData.type) {
      recipeRef = recipeRef.where("type", "==", filterData.type);
    }
    if (filterData.mealPlanId) {
      recipeRef = recipeRef.where("id", "==", filterData.mealPlanId);
    }
    return recipeRef.onSnapshot(
      async (querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot) => {
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });

        setAllRecipes(_.clone(data));
        data = data.filter((d) =>
          welnes
            ? d.nutritionistId === "welnes"
            : d.nutritionistId === userDetailsStorage.id
        );

        //group by name
        var recipeName = {},
          ignore = {};
        data.map((d) => {
          if (recipeName[d.name]) {
            ignore[d.key] = true;
            recipeName[d.name].push(d.key);
          } else recipeName[d.name] = [d.key];
        });

        setIgnore(ignore);
        setRecipesByName(recipeName);
        sortData({}, data, true);
        setRecipesLoader(false);
      },
      (error) => {
        console.error("error : ", error);
      }
    );
  }

  function getMealPlans() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setMealPlansLoader(true);
    return (
      firestore("meal_plans")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetails.id].concat(userDetails.collaborators || [])
        )
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            if (!documentSnapshot.data().name) {
              console.log("===>", documentSnapshot.id);
            }
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          setMealPlansLoader(false);
          setMealPlans(_.orderBy(data, "name", "asc"));
          var recipePlans = {};
          data.map((d) => {
            if (d.recipes)
              Object.values(d.recipes).map((recipes) => {
                recipes.map((r) => {
                  if (!recipePlans[r]) recipePlans[r] = {};
                  if (!recipePlans[r][d.name]) recipePlans[r][d.name] = 0;
                  recipePlans[r][d.name]++;
                });
              });
          });
          setRecipePlans(recipePlans);
        })
    );
  }

  function addMealPlan() {
    setMealPlanData({});
    setShowMealPlan(true);
  }

  function editMealPlan(mealPlan) {
    setMealPlanData(mealPlan);
    if (mealPlan.new === true) setShowMealPlan2(true);
    else setShowMealPlan(true);
  }

  function addMealPlan2() {
    setMealPlanData({});
    setShowMealPlan2(true);
  }

  async function onSelectPlan(selectedMeal) {
    await Promise.all(
      selectedRecords.map(async (o) => {
        const selectedIndex = mealPlans.findIndex(
          (mealPlan) => mealPlan.key === selectedMeal
        );
        await firestore("recipes").doc(o).update({
          mealPlanId: selectedMeal,
          mealPlanName: mealPlans[selectedIndex].name,
        });
      })
    );
    setSelectedRecords([]);
    setIsSelectAllSelected(false);
  }

  const onSelect = (key, isSelect) => {
    const selectedRows = [...selectedRecords];
    if (isSelect) {
      selectedRows.push(key);
    } else {
      const index = selectedRows.findIndex((o) => o === key);
      if (index > -1) {
        selectedRows.splice(index, 1);
      }
    }
    setSelectedRecords(selectedRows);
  };

  async function onDeleteClick(key) {
    setDocId(key);
    setShowDeleteModal(true);
  }

  function editRecipe(recipe = {}) {
    setRecipeData(recipe);
    setShow(true);
  }

  function cloneRecipe(recipe = {}) {
    delete recipe.key;
    setRecipeData(recipe);
    setShow(true);
  }

  function selectAllCheckbox(e) {
    setIsSelectAllSelected(e.target.checked);
    const selectedRows = [];
    if (e.target.checked) {
      recipes.forEach((o) => {
        selectedRows.push(o.key);
      });
    }
    setSelectedRecords(selectedRows);
  }

  function checkIsSelected(key) {
    if (selectedRecords.length) {
      const index = selectedRecords.findIndex((o) => o === key);
      return index > -1;
    }
    return false;
  }

  function removeFilter(key) {
    const filter = _.clone(filterData);
    delete filter[key];
    setFilterData(filter);
  }

  function displayFilter(filterKey) {
    if (filterKey === "mealPlanId") {
      const index = mealPlans.findIndex((o) => o.key === filterData[filterKey]);
      if (index > -1) {
        return mealPlans[index].name;
      }
    }
    return filterData[filterKey];
  }

  function sortData(filterField = "", data = [], isInitSort = false) {
    if (filterField !== "none") {
      let sortClone = _.clone(sort);
      if (filterField && !isInitSort) {
        if (sortClone.field === filterField) {
          sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
        } else {
          sortClone.field = filterField;
          sortClone.direction = "asc";
          if (sortClone.field == "datetime") sortClone.direction = "desc";
        }
        setSort(sortClone);
      }
      let usersClone = isInitSort ? data : recipes;
      usersClone = _.orderBy(
        _.clone(usersClone),
        (item) => {
          if (item[sortClone.field]) {
            if (sortClone.field === "day")
              return parseFloat(item[sortClone.field]);
            if (sortClone.field === "calories")
              return parseFloat(item[sortClone.field]);
            if (sortClone.field === "Calories")
              return parseFloat(item[sortClone.field]);
            if (sortClone.field === "datetime")
              return moment.utc(item[sortClone.field]).format();
            if (item[sortClone.field].toLowerCase)
              return item[sortClone.field].toLowerCase().trim();
          } else return item[sortClone.field];
        },
        sortClone.direction
      );
      //var method = function(item) { return moment.utc(item.datetime).format() }
      setRecipes(usersClone); //_.orderBy(usersClone, method, 'desc')
    }
  }

  function filterFn(recipe) {
    return (
      (selectedType ? recipe.type === selectedType : true) &&
      (selectedPlan
        ? (recipePlans[recipe.key] !== undefined &&
            recipePlans[recipe.key][selectedPlan] !== undefined) ||
          (recipesByName[recipe.name] !== undefined &&
            recipesByName[recipe.name].filter(
              (d) => recipePlans[d] && recipePlans[d][selectedPlan]
            ).length)
        : true) &&
      (searchKey
        ? recipe.name.toLowerCase().includes(searchKey.toLowerCase())
        : true) &&
      !ignore[recipe.key]
    );
  }
  return (
    <>
      <Header header="Meal Plans" />
      <hr />
      <div className="meal-plan">
        <div className="m-0 d-flex justify-content-between">
          <h4>Meal plans ({mealPlans.length})</h4>
          <button
            className="button button-secondary"
            onClick={(e) => {
              if (e.ctrlKey) addMealPlan2();
              else addMealPlan();
            }}
          >
            + Add meal plan
          </button>
        </div>
        <Card>
          <Card.Body>
            {mealPlansLoader ? (
              <div className="text-center">
                <Spinner animation="border" size={"sm"} role="status" />
              </div>
            ) : mealPlans.length ? (
              mealPlans.map((mealPlan) => {
                return (
                  <Button
                    key={mealPlan.key}
                    disabled={collaborators[mealPlan.nutritionistId]}
                    style={
                      collaborators[mealPlan.nutritionistId]
                        ? { cursor: "default" }
                        : {}
                    }
                    onClick={(e) => {
                      editMealPlan(mealPlan);
                    }}
                    className="rounded-pill mr-3 mb-3"
                    variant="outline-secondary"
                    size="sm"
                  >
                    {mealPlan.name}
                    {collaborators[mealPlan.nutritionistId] && (
                      <span
                        style={{
                          background: "wheat",
                          color: "black",
                          marginLeft: "10px",
                          boxShadow: "0 0 3px 5px wheat",
                          borderRadius: "5px",
                          fontVariant: "petite-caps",
                          fontWeight: "bold",
                        }}
                      >
                        {collaborators[mealPlan.nutritionistId].name}
                      </span>
                    )}
                  </Button>
                );
              })
            ) : (
              <div className="text-center">No Meal plans found</div>
            )}
          </Card.Body>
        </Card>

        <div className="filter-section">
          <div className="m-0 d-flex justify-content-between">
            <h4>
              Recipes ({numberWithCommas(recipes.length)})
              {filterData &&
                Object.keys(filterData).map((filterKey) => {
                  return filterData[filterKey] ? (
                    <Button
                      key={filterKey}
                      onClick={() => {
                        removeFilter(filterKey);
                      }}
                      className="rounded-pill ml-3"
                      variant="outline-secondary"
                      size="sm"
                    >
                      {displayFilter(filterKey)}
                    </Button>
                  ) : (
                    ""
                  );
                })}
            </h4>
            <div className="search-bar">
              <Form.Control
                id="search-recipe"
                placeholder="search"
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
              ></Form.Control>
              <FontAwesomeIcon icon={faSearch} className="ml-2" size="1x" />
            </div>
            <div className="welnes-recipes">
              <Form.Check
                checked={recipe === 1}
                inline
                label="My Recipes"
                name="group1"
                type="radio"
                id="inline-radio-1"
                onChange={(e) => {
                  setWelnes(!e.target.checked);
                  setRecipe(1);
                }}
              />
              <Form.Check
                checked={recipe === 2}
                inline
                label="Welnes Recipes"
                name="group1"
                type="radio"
                id={`inline-radio-2`}
                onChange={(e) => {
                  setWelnes(e.target.checked);
                  setRecipe("welnes");
                  setRecipe(2);
                }}
              />
            </div>
            <div className="add-recipe">
              {/* {items && !welnes && (
								<Button
									size="sm"
									variant="primary"
									className="text-uppercase ml-2"
									onClick={() => editRecipe()}
								>
									<FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
									Add Recipe
								</Button>
							)} */}
              {items && !welnes && (
                <button
                  className="button button-secondary"
                  onClick={() => editRecipe()}
                >
                  + Add Recipe
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="recipe-section">
          <div className="m-0 d-flex align-items-center">
            <h5>
              <Filter fill="#383838" width="15%" style={{ margin: "0.5em" }} />
              Filter by
            </h5>

            {recipeTypes.length > 1 ? (
              <Form.Control
                as="select"
                placeholder="Select Type"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <option value={""} key={""}>
                  All types
                </option>
                {recipeTypes.map((obj, i) => {
                  return (
                    <option value={obj} key={obj}>
                      {obj}
                    </option>
                  );
                })}
              </Form.Control>
            ) : null}

            {mealPlans.length > 1 ? (
              <Form.Control
                as="select"
                placeholder="Select Plan"
                value={selectedPlan}
                onChange={(e) => {
                  setSelectedPlan(e.target.value);
                }}
              >
                <option value={""} key={""}>
                  Any meal plan
                </option>
                {mealPlans.map((obj, i) => {
                  return (
                    <option value={obj.name} key={obj.name}>
                      {obj.name}
                    </option>
                  );
                })}
              </Form.Control>
            ) : null}

            {/* <Form.Control
							as="select"
							placeholder="Select Recipe"
							value={welnes}
							onChange={(e) => {
								if (e.target.value === "My Recipes") {
									setWelnes(false);
								} else {
									setWelnes("Welnes Recipes");
								}
							}}
						>
							{recipefilter.map((rec, i) => (
								<option value={rec.name} key={i}>
									{rec.name}
								</option>
							))}
						</Form.Control> */}

            <h5 className="sort-items">Sort By</h5>
            <Form.Control
              className="select-sort"
              as="select"
              placeholder="Sort"
              value={selectedSort.name}
              onChange={(e, value) => {
                setSelectedSort(
                  selectedSortData.filter(
                    (obj) => obj.name === e.target.value
                  )[0]
                );
                let sortValue =
                  e.target.value === "Date"
                    ? e.target.value.toLowerCase().concat("time")
                    : e.target.value === "Calories"
                    ? e.target.value
                    : e.target.value.toLowerCase();

                sortData(sortValue);
              }}
            >
              {selectedSortData.map((obj, i) => {
                return (
                  <option value={obj.name} key={obj.name}>
                    {obj.name}
                  </option>
                );
              })}
            </Form.Control>
            {/* { sort.field === selectedSort.filterField ? ( */}
            <span
              style={
                selectedSort.filterField
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={(e) => {
                sortData(selectedSort.filterField);
              }}
            >
              <FontAwesomeIcon
                icon={sort.direction === "asc" ? faArrowUp : faArrowDown}
                className="ml-1"
                size="lg"
              />
            </span>
          </div>
          <hr />
          <div className={recipes.length ? "recipe-plans-grid" : ""}>
            {recipesLoader ? (
              <div>
                <div className="text-center" colSpan={tableHeaders.length + 1}>
                  <Spinner animation="border" size={"sm"} role="status" />
                </div>
              </div>
            ) : recipes.length ? (
              <>
                {recipes.filter(filterFn).map(
                  (recipe, i) =>
                    i < nItems && (
                      <RecipeCard
                        ind={1000}
                        key={recipe.key}
                        image={recipe.image}
                        name={recipe.name}
                        type={recipe.type}
                        calories={
                          recipesByName[recipe.name] &&
                          recipesByName[recipe.name].length > 1 ? (
                            <span>
                              {recipesByName[recipe.name].length +
                                ` variations`}{" "}
                            </span>
                          ) : (
                            <span>
                              {recipe.type + " - "}
                              {recipe.Calories || recipe.calories} Kcal
                            </span>
                          )
                        }
                        recipe={recipe}
                        recipes={recipes}
                        readOnly={welnes}
                        setVariations={setVariations}
                        cloneRecipe={cloneRecipe}
                        editRecipe={editRecipe}
                        onDeleteClick={onDeleteClick}
                        plan={
                          recipePlans &&
                          recipePlans &&
                          (recipesByName[recipe.name] &&
                          recipesByName[recipe.name].length > 1
                            ? _.uniq(
                                [].concat.apply(
                                  [],
                                  recipesByName[recipe.name].map((r) =>
                                    Object.keys(recipePlans[r] || {})
                                  )
                                )
                              )
                            : recipePlans[recipe.key] &&
                              Object.keys(recipePlans[recipe.key]))
                        }
                      />
                    )
                )}
              </>
            ) : (
              <div>
                <div className="text-center" colSpan={tableHeaders.length + 1}>
                  No data found
                </div>
              </div>
            )}
          </div>
        </div>

        {nItems < recipes.filter(filterFn).length && (
          <div className="loader text-center">
            <Spinner animation="border" size={"sm"} role="status" />
          </div>
        )}

        <Modal
          show={variations ? true : false}
          onHide={() => setVariations(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>recipe variations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="recipes">
              {variations &&
                variations.map((recipe, i) => (
                  <div className="recipe-variations" key={recipe.key}>
                    <RecipeCard
                      variation={true}
                      ind={1000}
                      key={recipe.key}
                      image={recipe.image}
                      name={recipe.name}
                      type={recipe.type}
                      calories={
                        <span>{recipe.Calories || recipe.calories} Kcal</span>
                      }
                      recipe={recipe}
                      recipes={recipes}
                      readOnly={welnes}
                      setVariations={setVariations}
                      cloneRecipe={cloneRecipe}
                      editRecipe={editRecipe}
                      onDeleteClick={onDeleteClick}
                      plan={
                        recipePlans &&
                        recipePlans[recipe.key] &&
                        Object.keys(recipePlans[recipe.key]).map((plan) => plan)
                      }
                    />
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-60w"
        >
          <AddRecipe
            handleClose={handleClose}
            recipeData={recipeData}
            recipeTypes={recipeTypes}
            recipeTags={recipeTags}
            ingredients={items}
            readOnly={welnes}
            mealPlans={mealPlans}
          />
        </Modal>
        <Modal
          show={showMealPlan}
          onHide={handleCloseMealPlan}
          className="edit-mealplan"
        >
          <AddMealPlan
            handleClose={handleCloseMealPlan}
            mealPlanData={mealPlanData}
            mealPlans={mealPlans}
            recipes={allRecipes}
            recipeTypes={recipeTypes}
            recipeTags={recipeTags}
            cloneRecipe={cloneRecipe}
          />
        </Modal>
        <Modal
          show={showMealPlan2}
          onHide={handleCloseMealPlan}
          className="edit-mealplan"
        >
          <AddMealPlan2
            handleClose={handleCloseMealPlan}
            mealPlanData={mealPlanData}
            mealPlans={mealPlans}
            recipes={allRecipes}
            recipeTypes={recipeTypes}
            recipeTags={recipeTags}
            cloneRecipe={cloneRecipe}
          />
        </Modal>
        <Modal show={showFilter} onHide={() => setShowFilter(false)}>
          <RecipeFilter
            handleClose={(dismissData) => {
              setShowFilter(false);
              if (dismissData.filterData) {
                setFilterData(dismissData.filterData);
              }
              getRecipes();
            }}
            filterData={filterData}
            recipeTypes={recipeTypes}
            recipeTags={recipeTags}
            mealPlans={mealPlans}
          />
        </Modal>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <DeleteAlert
            onHide={() => setShowDeleteModal(false)}
            title={"Delete Recipe"}
            message={"Are you sure you want to delete recipe?"}
            docId={docId}
            collectionName={"recipes"}
          />
        </Modal>
      </div>
    </>
  );
}
